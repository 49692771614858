import { Box } from '@core/component';
import { EZIndexName } from '@core/type';

import { HomepageBackgroundProps } from '../interface-home';

export const HomepageBackground = ({ image, children }: HomepageBackgroundProps) => (
  <Box
    backgroundImage={`url(${image})`}
    backgroundRepeat="repeat-y"
    backgroundSize="100% 50%"
    zIndex={EZIndexName.AUTO}
  >
    {children}
  </Box>
);
