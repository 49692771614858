import loadNamespaces from 'next-translate/loadNamespaces';

import { ELanguage, ERouting, PageCMS } from '@core/type';
import { HomePageEntity } from '@core/type/api';

import { makeServerProps } from '../helpers/make-server-props/make-server-props';
import { useCMSPage } from '../hooks/use-cms-page';
import { ViewHome } from '../view/home/ViewHome';

export type ViewHomeProps = PageCMS<HomePageEntity, unknown, unknown>;

const translationDefault = async () => ({
  ...(await loadNamespaces({ locale: ELanguage.EN, pathname: '*' })),
  ...(await loadNamespaces({ locale: ELanguage.EN, pathname: '/' })),
});

export const PageHome = (props: ViewHomeProps) => useCMSPage({ ViewPage: ViewHome, ...props });

const getServerSideProps = makeServerProps({
  keyRoute: ERouting.HOME,
  translationDefault,
  hasSanity: true,
});

export { getServerSideProps };

export default PageHome;
