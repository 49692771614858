import { useLiveQuery } from '@sanity/preview-kit';

import { Container } from '@core/component';
import { useContextMediaQuery } from '@core/context';
import { useLanguage } from '@core/hook';
import { getStaticPathIconFolder } from '@core/media';
import { applyAbTest, getSanityQuery } from '@core/sanity/utils';
import { ERouting, EZIndexName } from '@core/type';
import { HomePageEntity } from '@core/type/api';
import { ESanityWidget } from '@core/type/sanity';

import { getSanityComponent } from '../../layout';
import { HomepageBackground } from './components/HomepageBackground';
import { InvestmentAssistantBlock } from './components/InvestmentAssistantBlock';
import { SecureSavingsBlock } from './components/SecureSavingsBlock';

export const ViewHome = ({ cms, shouldUseVariation }: HomePageEntity) => {
  const { language } = useLanguage();
  const { isDesktop } = useContextMediaQuery();

  const [data] = useLiveQuery(cms || null, getSanityQuery(ERouting.HOME, {}, language));

  const cmsResult = applyAbTest(data, shouldUseVariation);

  return (
    <HomepageBackground
      image={
        isDesktop
          ? getStaticPathIconFolder('homepage/assistant-background.svg')
          : getStaticPathIconFolder('homepage/assistant-background-tablet.svg')
      }
    >
      {cmsResult?.body?.pageBuilder?.map((section) => {
        switch (section._type) {
          case ESanityWidget.INVESTMENT_ASSISTANT:
            return (
              <Container key={section._key}>
                <InvestmentAssistantBlock />
              </Container>
            );
          case ESanityWidget.SECURE_SAVING:
            return (
              <Container key={section._key}>
                <SecureSavingsBlock />
              </Container>
            );
          case ESanityWidget.CAROUSEL_PRODUCT:
          case ESanityWidget.CAROUSEL_BANNER:
            return (
              <Container position={'relative'} zIndex={EZIndexName.OVER_DEFAULT} key={section._key}>
                {getSanityComponent(section, cmsResult?._id)}
              </Container>
            );
          default:
            return (
              <Container position={'relative'} zIndex={EZIndexName.OVER_DEFAULT} key={section._key}>
                {getSanityComponent(section, cmsResult?._id)}
              </Container>
            );
        }
      })}
    </HomepageBackground>
  );
};
